import Image from "next/image";
import { ReactElement } from "react";
import style from "./styles.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="mx-auto px-5 lg:max-w-7xl flex flex-col-reverse md:flex-row justify-between items-center">
                <div className="md:w-10/12">
                    <h2>What Was the Defect in the 3M Combat Earplugs?</h2>
                    <p>
                        The earplugs are yellow on one side and green on the
                        other, each indicating the level of noise protection.
                        The defective design allegation is that the earplug
                        itself is too short, causing the earplugs to loosen and
                        shift out of the ear canal. The shift out of the ear
                        canal goes unnoticed by the user, and subjects military
                        service members to dangerous and damaging noise levels,
                        causing tinnitus or even hearing loss.
                    </p>
                </div>
                <div className="relative md:ml-10 w-[248px] h-[216px] mb-10 md:mb-0">
                    <Image
                        src="/bestearpluglawyers/assets/images/earplugsOpt.png"
                        alt="earplug"
                        layout="fill"
                    />
                </div>
            </div>
        </div>
    );
}
